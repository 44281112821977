import { cloneDeep, isEqual } from "lodash";
export const ChecksAdminStore = {
	state: {
		checksData: null,
		originChecksData: null,
		isDirty: false
	},
	mutations: {
		SET_CHECK_DATA(state, data) {
            state.checksData = cloneDeep(data);
            state.originChecksData = cloneDeep(data);
            state.isDirty = false;
        },
        SET_CHECK_DATA_DIRTY(state, payload) {
            // eslint-disable-next-line no-unused-vars
            let { entity_types, parameters, ...restPayload } = payload;
            // eslint-disable-next-line no-unused-vars
            const { entity_types: origin_entity_types, parameters: origin_parameters , ...restoriginData } = state.originChecksData;

            const collectionNames = [
                'entity_types'
            ];

            const entity_typesModel = {
                id: 'string',
                maximum_entity_count: 'number',
                minimum_entity_count: 'number',
                name: 'string'
            }
            
            const isCollectionsEqual = collectionNames.every(collectionName => {
                let isEqual = true;

                const source = state.originChecksData[collectionName];
                const target = payload[collectionName]

                if (source.length != target.length) {
                    isEqual = false;
                } else {
                    for (let i = 0; i < source.length; i++) {
                        const sourceItem = source[i]
                        const targetItem = target.find(x => x.id == sourceItem.id)
                        if (!targetItem) {
                            isEqual = false;
                            break;
                        } else {
                            for (const key in entity_typesModel) {
                                if (sourceItem[key] != targetItem[key]) {
                                    isEqual = false;
                                    break;
                                }
                            }
                        }
                    }
                }
                return isEqual;
            })
            
            state.isDirty = !isCollectionsEqual || !isEqual(cloneDeep(restPayload), cloneDeep(restoriginData))
        },
	},
	actions: {
        setCheckData: ({ commit }, payload) => {
             commit('SET_CHECK_DATA', payload);
        },
		
        handleCheckDataChange: ({ commit }, payload) => {
            commit('SET_CHECK_DATA_DIRTY', payload);
        }
	}
};

