import Vue from "vue";
import Vuex from "vuex";
import { AuthStore } from "@/modules/auth/store";
import { FieldsAminStore } from "@/modules/fields-admin/store";
import { PackageStore } from "@/modules/packages/store"
import { CaseStore } from "@/modules/case/store";
import { ProfileStore } from "@/modules/profile/store";
import ThemeStore from "@/modules/theme/store";
import { DashboardStore } from "@/modules/dashboard/store"
import { LightRayStore } from "@shared/light-ray/store"
import { FormsAdminStore } from "@/modules/form-admin/store"
import { ChecksAdminStore } from "@/modules/checks/store"
import { BlocksAdminStore } from "@/modules/blocks-admin/store"

import axios from "@/axios";

Vue.use(Vuex);

export const RootStore = {
  state: {
    tenant_id: null,
    isNavCollapsed: window.localStorage.getItem("nav_collapsed"),
    form_rows: [],
    entityTypeList: [],
  },
  getters: {
    getTenantId: (state) => state.tenant_id,
    getNavCollapsed: (state) => state.isNavCollapsed,
    getFormRows: (state) => state.form_rows
  },
  mutations: {
    SET_NAV_COLLAPSE(state, payload) {
      state.isNavCollapsed = payload;
    },
    SET_TENANT_ID(state, payload) {
      state.tenant_id = payload;
    },
    SET_FORM_ROWS(state, payload) {
      state.form_rows = payload;
    },
    SET_ENTITY_TYPE_LIST(state, payload) {
      state.entityTypeList = payload;
    },
  },
  actions: {
    setUser({commit} , payload) {
      commit("SET_USER", payload)
    },
    setNavCollapsed({ commit }, payload) {
      window.localStorage.setItem("nav_collapsed", payload);
      commit("SET_NAV_COLLAPSE", payload);
    },
    setTenantId({ commit }, payload) {
      commit("SET_TENANT_ID", payload);
    },
    async fetchTenantId({ commit }) {
      try {
        const { data } = await axios.post(`user-auth/init`, {});
        if (data) {
          commit("SET_TENANT_ID", data?.tenant_id);
          commit("SET_USER", data.user_details)
        }
      } catch (error) {
        console.log(error.message || `ERROR FETCHING TENANT ID`);
      }
    },
    async fetchFormRows({commit}, payload) {
      try {
          const { data } = await axios.get("/tenant-form", payload);
          // this.rows = data?.data ?? [];
          if (data) {
            commit("SET_FORM_ROWS", data?.data || []);
          }
      } catch (error) {
          console.log("[+] error while fetching tenant-form  :>> ", error);
      }
    },
    async fetchEntityTypeList({commit}) {
      try {
        const { data } = await axios.get(`entity`) 
        if (data) {
          commit("SET_ENTITY_TYPE_LIST", data || [])
        }
      } catch (error) {
        console.log('error :>> ', error);
      }
    }
  },
  modules: {
    auth: {
      ...AuthStore,
    },
    fieldsAdmin: { ...FieldsAminStore },
    CaseStore: { ...CaseStore },
    PackageStore: {...PackageStore},
    formsAdmin: {...FormsAdminStore},
    checksAdmin: {...ChecksAdminStore},
    blocksAdmin: {...BlocksAdminStore},
    ProfileStore: {...ProfileStore},
    DashboardStore: {...DashboardStore},
    ThemeStore: {...ThemeStore},
    LightRayStore: {...LightRayStore}
  },
};

const store = new Vuex.Store(RootStore);

export default store;
