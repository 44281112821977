export const License = [
    {
        path: "/license",
        component: () => import(/* webpackChunkName: "license-pack" */ "@/pages/license/index.vue"),
        meta: {
            breadcrumb: [{ name: "Admin" }, { name: "License" }],
            title: "License",
        },
        children: [
            {
                path: "/",
                name: "license-list",
                component: () => import(/* webpackChunkName: "license list" */ "@/pages/license/license-list"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "License" }, { name: "License Lists" }],
                    title: "License",
                },
            },
            {
                path: "add/:tab?",
                name: "license-add",
                component: () => import(/* webpackChunkName: "license add" */ "@/pages/license/license-details"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "License" }, { name: "License Lists" }],
                    title: "License",
                },
            },
            {
                path: "details/:id/:tab?",
                name: "license-details",
                component: () => import(/* webpackChunkName: "license details" */ "@/pages/license/license-details"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "License" }, { name: "License Lists" }, {name: 'details'}],
                    title: "License",
                },
            },
        ],
    },
];
